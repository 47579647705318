export const layoutBreakpoints = { lg: 1200, md: 960, sm: 720, xs: 480, xxs: 0 };
export const layoutCols = { lg: 48, md: 24, sm: 12, xs: 10, xxs: 8 };

export const DIATlayout = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 22.5, "h": 5, "x": 1, "y": 4, "i": "1", "minW": 15, "minH": 3, "maxH": 8 },
    { "w": 22.5, "h": 5, "x": 24, "y": 4, "i": "2", "minW": 20, "minH": 2, "maxH": 8 },
    // { "w": 16, "h": 5, "x": 31, "y": 9, "i": "3", "minW": 13, "minH": 5 },
    { "w": 46, "h": 4, "x": 1, "y": 10, "i": "4", "minW": 28, "minH": 3, "maxH": 8 },
    { "w": 23, "h": 8, "x": 1, "y": 15, "i": "9", "minW": 23, "minH": 7, "maxH": 10 },
    { "w": 22, "h": 8, "x": 25, "y": 15, "i": "3", "minW": 22, "minH": 7, "maxH": 10 },
    { "w": 23, "h": 8, "x": 1, "y": 24, "i": "8", "minW": 21, "minH": 5, "maxH": 10 },
    { "w": 22, "h": 4, "x": 25, "y": 24, "i": "5", "minW": 17, "minH": 4, "maxH": 8 },
    // { "w": 25, "h": 8, "x": 31, "y": 14, "i": "9", "minW": 13, "minH": 4 },
];

export const DIATlayouts = {
    lg: DIATlayout,
    md: DIATlayout,
    sm: DIATlayout,
    xs: DIATlayout,
};

export const policyLayout = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 2, "maxH": 3 },
    { "w": 20, "h": 3, "x": 1, "y": 3, "i": "1", "minW": 20, "minH": 3, "maxH": 4 },
    { "w": 20, "h": 3, "x": 1, "y": 6, "i": "3", "minW": 18, "minH": 3, "maxH": 4 },
    { "w": 25, "h": 3, "x": 22, "y": 6, "i": "4", "minW": 18, "minH": 3, "maxH": 4 },
    { "w": 25, "h": 3, "x": 22, "y": 3, "i": "2", "minW": 23, "minH": 3, "maxH": 4 },
    { "w": 46, "h": 4, "x": 1, "y": 9, "i": "5", "minW": 46, "minH": 3, "maxH": 5 }
];

export const policyLayouts = {
    lg: policyLayout,
    md: policyLayout,
    sm: policyLayout,
    xs: policyLayout,
};

export const highTouchLayout = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 48, "h": 0.5, "x": 2, "y": 3, "i": "title", "minW": 48, "minH": 0.5, static: true },
    { "w": 46, "h": 4.5, "x": 1, "y": 3, "i": "1", "minW": 21, "minH": 3, "maxW": 46, "maxH": 7 },
    { "w": 22.5, "h": 4, "x": 1, "y": 15, "i": "2", "minW": 15, "minH": 3.5, "maxW": 34, "maxH": 5 },
    { "w": 46, "h": 5, "x": 1, "y": 7, "i": "3", "minW": 35, "minH": 4, "maxW": 46, "maxH": 6 },
    { "w": 26, "h": 4, "x": 1, "y": 7, "i": "4", "minW": 15, "minH": 3, "maxW": 46, "maxH": 7 },
    { "w": 22.5, "h": 4, "x": 24.5, "y": 15, "i": "5", "minW": 15, "minH": 3.5, "maxW": 34, "maxH": 5 },
    { "w": 46, "h": 4, "x": 1, "y": 19, "i": "6", "minW": 30, "minH": 3, "maxW": 46, "maxH": 5 },
];

export const highTouchLayouts = {
    lg: highTouchLayout,
    md: highTouchLayout,
    sm: highTouchLayout,
    xs: highTouchLayout,
};

export const lowTouchLayout = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 48, "h": 0.5, "x": 2, "y": 3, "i": "title", "minW": 48, "minH": 0.5, static: true },
    { "w": 46, "h": 3.5, "x": 1, "y": 3.5, "i": "1", "minW": 46, "minH": 3, "maxW": 46, "maxH": 4 },
    { "w": 46, "h": 4, "x": 1, "y": 7.5, "i": "2", "minW": 46, "minH": 3, "maxW": 46, "maxH": 7 },
    { "w": 46, "h": 3.5, "x": 1, "y": 14.5, "i": "3", "minW": 46, "minH": 3, "maxW": 46, "maxH": 4 },
    { "w": 46, "h": 7, "x": 1, "y": 18.5, "i": "4", "minW": 46, "minH": 4, "maxW": 46, "maxH": 8 }
];

export const lowTouchLayouts = {
    lg: lowTouchLayout,
    md: lowTouchLayout,
    sm: lowTouchLayout,
    xs: lowTouchLayout,
};

export const ONALayout = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 21, "h": 2, "x": 1, "y": 7, "i": "insight" },

    { "w": 22, "h": 5, "x": 1, "y": 3, "i": "1", "minW": 15, "minH": 3, "maxW": 46, "maxH": 7 },
    { "w": 22, "h": 5, "x": 25, "y": 9, "i": "2", "minW": 22, "minH": 3, "maxW": 46, "maxH": 7 },

    { "w": 22, "h": 6, "x": 1, "y": 12, "i": "3", "minW": 15, "minH": 3, "maxW": 46, "maxH": 7 },
    { "w": 22, "h": 6, "x": 25, "y": 12, "i": "4", "minW": 20, "minH": 4, "maxW": 46, "maxH": 7 },

    { "w": 46, "h": 7, "x": 1, "y": 23, "i": "9", "minW": 46, "minH": 7, "maxW": 46, "maxH": 7 },
    { "w": 46, "h": 5, "x": 1, "y": 32, "i": "6", "minW": 35, "minH": 5, "maxW": 46, "maxH": 7 },

    //{ "w": 23, "h": 8, "x": 1, "y": 24, "i": "8", "minW": 21, "minH": 3 }
];

export const ONALayouts = {
    lg: ONALayout,
    md: ONALayout,
    sm: ONALayout,
    xs: ONALayout,
};

export const OverallCultural = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 48, "h": 0.5, "x": 2, "y": 3, "i": "title", "minW": 48, "minH": 0.5, static: true },
    { "w": 22.5, "h": 4, "x": 1, "y": 4, "i": "4", "minW": 20, "minH": 3, "maxW": 22.5, "maxH": 5 },
    { "w": 22.5, "h": 4, "x": 24, "y": 4, "i": "1", "minW": 20, "minH": 3, "maxW": 22.5, "maxH": 5 },
    { "w": 46, "h": 4, "x": 1, "y": 9, "i": "2", "minW": 46, "minH": 3, "maxW": 46, "maxH": 5 },
    { "w": 46, "h": 7, "x": 1, "y": 12, "i": "3", "minW": 46, "minH": 4, "maxW": 46, "maxH": 13 }
];

export const OverallCulturalLayouts = {
    lg: OverallCultural,
    md: OverallCultural,
    sm: OverallCultural,
    xs: OverallCultural,
};

export const OverallDIATlayout = [
    { "w": 48, "h": 3, "x": 0, "y": 0, "i": "banner", "minW": 48, "minH": 3, "maxH": 3 },
    { "w": 22, "h": 8, "x": 1, "y": 3, "i": "1", "minW": 15, "minH": 3 },
    { "w": 22, "h": 8, "x": 25, "y": 9, "i": "2", "minW": 15, "minH": 4 },
    { "w": 46, "h": 4, "x": 1, "y": 10, "i": "13", "minW": 28, "minH": 3 },
    { "w": 23, "h": 8, "x": 1, "y": 24, "i": "8", "minW": 21, "minH": 5, "maxH": 10 },
    { "w": 22, "h": 4, "x": 25, "y": 24, "i": "11", "minW": 17, "minH": 4, "maxH": 5 },
    { "w": 22, "h": 4, "x": 25, "y": 24, "i": "12", "minW": 19, "minH": 4, "maxH": 6, "MaxW": 24 },
    { "w": 23, "h": 8, "x": 1, "y": 15, "i": "9", "minW": 23, "minH": 7, "maxH": 10 },
    { "w": 22, "h": 8, "x": 25, "y": 15, "i": "3", "minW": 22, "minH": 7, "maxH": 10 },
    { "w": 46, "h": 5, "x": 1, "y": 32, "i": "6", "minW": 35, "minH": 5, "maxH": 8 }
];

export const OverallDIATlayouts = {
    lg: OverallDIATlayout,
    md: OverallDIATlayout,
    sm: OverallDIATlayout,
    xs: OverallDIATlayout,
};

const TALayout = [

    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "6.1", "moved": false, "static": false, "minW": 30, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "6.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "6.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "6.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "6.6", "moved": false, "static": false, "minW": 26, "minH": 2, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 13, "i": "6.5", "moved": false, "static": false, "minW": 30, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 9, "x": 23, "y": 3, "i": "6.9", "minW": 48, "minH": 3 }
]
const TALayout1 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "1.1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "1.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "1.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "1.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "1.6", "moved": false, "static": false, "minW": 26, "minH": 2, "maxW": 48, "maxH": 5 }
]
const TALayout2 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "2.1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "2.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "2.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "2.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "2.6", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 }
]
const TALayout3 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "3.1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "3.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "3.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "3.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "3.6", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 }
]
const TALayout4 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "4.1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "4.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "4.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "4.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "4.6", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 }
]
const TALayout5 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "5.1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "5.3", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "5.4", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "5.7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "5.6", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 }
]
const TALayout7 = [
    { "w": 48, "h": 2, "x": 0, "y": 0, "i": "banner", "minW": 48, "maxW": 48, "minH": 1, "maxH": 2, "moved": false, "static": true },
    { "w": 48, "h": 1, "x": 0, "y": 2, "i": "filters", "minW": 48, "maxW": 48, "minH": 1, "maxH": 1, "moved": false, "static": true },
    { "w": 46, "h": 2, "x": 1, "y": 3, "i": "1", "moved": false, "static": false, "minW": 18, "minH": 2, "maxW": 48, "maxH": 2 },
    { "w": 46, "h": 3, "x": 1, "y": 5, "i": "3", "moved": false, "static": false, "minW": 48, "minH": 5, "maxW": 48, "maxH": 5 },
    { "w": 21, "h": 2, "x": 1, "y": 11, "i": "4", "moved": false, "static": false, "minW": 15, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 8, "i": "7", "moved": false, "static": false, "minW": 48, "minH": 3, "maxW": 48, "maxH": 5 },
    { "w": 24, "h": 2, "x": 23, "y": 11, "i": "6", "moved": false, "static": false, "minW": 26, "minH": 2.5, "maxW": 48, "maxH": 5 },
    { "w": 46, "h": 3, "x": 1, "y": 13, "i": "5", "moved": false, "static": false },
    { "w": 24, "h": 9, "x": 23, "y": 3, "i": "9", "minW": 48, "minH": 3 },
]
export const TALayouts = {
    "6": {
        lg: TALayout,
        md: TALayout,
        sm: TALayout,
        xs: TALayout,
    },
    "1": {
        lg: TALayout1,
        md: TALayout1,
        sm: TALayout1,
        xs: TALayout1,
    },
    "2": {
        lg: TALayout2,
        md: TALayout2,
        sm: TALayout2,
        xs: TALayout2,
    },
    "3": {
        lg: TALayout3,
        md: TALayout3,
        sm: TALayout3,
        xs: TALayout3,
    },
    "4": {
        lg: TALayout4,
        md: TALayout4,
        sm: TALayout4,
        xs: TALayout4,
    },
    "5": {
        lg: TALayout5,
        md: TALayout5,
        sm: TALayout5,
        xs: TALayout5,
    },
    "7": {
        lg: TALayout7,
        md: TALayout7,
        sm: TALayout7,
        xs: TALayout7,
    }
}

